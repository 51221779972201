$colors: ('blue-light'#7bf0ff,
	'blue'#1ad9ff,
	'blue-dark'#1699bb,
	'blue-darkist'#323663,
	'red'#ef2950,
	'yellow-light'#eaea8f,
	'yellow'#f9dc5c,
	'yellow-dark'#c3a830,
	'grey-light'#ccc,
	'grey'#6d708c,
	'grey-dark'#878ba4,
	'dark'#14151c,
	'white'#fff,
	'code-purple'#D13BCC);

:root {

	@each $name,
	$value in $colors {
		--#{$name}: #{$value};
	}
}

@each $name,
$value in $colors {
	.color-#{$name} {
		color: var(--#{$name});
	}
}