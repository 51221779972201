// breakpoint, spacing factor, spacing base value, type factor, type base value

$scale: (1 "xs"2 1.5 1.15 14,
	480 "sm"2 2 1.16 14,
	768 "md"2 3 1.165 14,
	992 "lg"2 4 1.17 16,
	1200 "xl"2 5 1.18 16);

$spacing: ("ty"0,
	"xs"1,
	"sm"2,
	"md"3,
	"lg"4,
	"xl"5);

$typeramp: ("h1"6 1.2,
	"h2"5 1.2,
	"h3"4 1.25,
	"h4"3 1.25,
	"h5"2 1.4,
	"h6"1 1.5,
	"p"0 2,
	"a"0 1,
	"li"0 2);

body {

	@each $breakpoint,
	$breakpointName,
	$spacingFactor,
	$spacingBase,
	$typeFactor,
	$typeBase in $scale {

		@media screen and (min-width: '#{$breakpoint}px') {

			@each $spacingName,
			$spacingExponent in $spacing {
				$value: #{round($spacingBase * pow($spacingFactor, $spacingExponent))};
				$valueExpanded: #{round($spacingBase * pow($spacingFactor, $spacingExponent) * 1.5)};

				--spacing-#{$spacingName}: #{$value}px;
				--spacing-#{$spacingName}-expanded: #{$valueExpanded}px;

				.spacing-#{$spacingName}-inset {
					padding: var(--spacing-#{$spacingName});
				}

				.spacing-#{$spacingName}-inset-squished {
					padding: var(--spacing-#{$spacingName}) var(--spacing-#{$spacingName}-expanded);
				}

				.spacing-#{$spacingName}-inset-expanded {
					padding: var(--spacing-#{$spacingName}-expanded) var(--spacing-#{$spacingName});
				}

				.spacing-#{$spacingName}-inline {
					margin-right: var(--spacing-#{$spacingName});
				}

				.spacing-#{$spacingName}-stack {
					margin-bottom: var(--spacing-#{$spacingName});
				}
			}
		}

		@media screen and (min-width: '#{$breakpoint}px') {

			@each $typeName,
			$typeExponent,
			$typeLineHeight in $typeramp {
				$value: #{round($typeBase * pow($typeFactor, $typeExponent))};

				--type-#{$typeName}: #{$value}px;
				--type-#{$typeName}-line-height: #{$typeLineHeight};

				#{$typeName} {
					font-size: var(--type-#{$typeName});
					line-height: var(--type-#{$typeName}-line-height);
				}
			}

			.container {
				margin: 0 auto;

				@if $breakpoint < 770 {
					max-width: 100%;
				}

				@else {
					max-width: #{$breakpoint}px;
				}

			}
		}
	}
}



.normal {
	font-weight: 400;
}

.medium {
	font-weight: 500;
}

.demi {
	font-weight: 600;
}

.bold {
	font-weight: 700;
}

.extra-bold {
	font-weight: 800;
}

.black {
	font-weight: 900;
}