h1,
h2,
h3,
h4,
h5,
h6 {
	color: var(--dark);
}

p {
	color: var(--grey);
}

.is-centered {
	text-align: center;
}

.is-full {
	display: block;
}