@charset "utf-8";
/* CSS Document */
@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@400;600;700;800;900&display=swap');

/*------------------------------*/
/*  GENERAL RESET              */
/*----------------------------*/
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
font,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
hr,
button {
	text-decoration: none;
	margin: 0;
	padding: 0;
	border: 0;
	outline: 0;
	font-size: 100%;
	background: none;
	font-weight: 400;
	font-family: 'Nunito Sans', sans-serif;
}

a {
	color: inherit;
}

a:hover {
	text-decoration: none;
	color: inherit;
}

ol,
ul {
	list-style: none;
}

h1,
h2,
h3,
h4,
h5,
h6,
li {
	line-height: 100%;
}

blockquote,
q {
	quotes: none;
}

q:before,
q:after {
	content: '';
}

table {
	border-collapse: collapse;
	border-spacing: 0;
}

input,
textarea,
select {
	font: 11px Arial, Helvetica, sans-serif;
	vertical-align: middle;
	padding: 0;
	margin: 0;
}

input:focus,
textarea:focus {
	outline: none;
}

form,
fieldset {
	border-style: none;
}

html,
body {
	margin: 0;
	padding: 0;
	background-color: #fff;
	color: var(--dark);
	font-size: 10px;
}

body {
	padding-top: 60px;
}

span {
	font-weight: inherit;
}

input,
label,
select,
button,
textarea {
	margin: 0;
	border: 0;
	padding: 0;
	display: inline-block;
	vertical-align: middle;
	white-space: normal;
	background: none;
	line-height: 1;

	font-size: 13px;
	font-family: Arial;
}