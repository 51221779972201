.button {
	display: inline-block;
	cursor: pointer;
	padding: 15px 30px;
	font-size: var(--type-p);
	border-radius: 50px;
	font-weight: 600;

	&.cta {
		background-color: var(--red);
		color: #fff;
	}
}