.row {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;

	.col {
		flex: 0 0 33%;
		min-width: 300px;
	}

	@for $i from 0 through 6 {
		.col-#{$i} {
			flex: $i;
		}
	}

	// .item {

	// }

	&.is-centered {
		justify-content: center;
		text-align: center;
	}

	&.is-spaced {
		justify-content: space-between;
	}
}

@media screen and (max-width: '#{nth(nth($scale, 3), 1)}px') {
	.row {
		justify-content: center;

		[class^="col"] {
			flex: 1 1 100%;
			margin-bottom: var(--spacing-md);
		}
	}
}